// custom/components/_avatar.scss

// truncates long text with ellips...
.text-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// enlarge xs avatar slightly
// set min-width to avatars to fix text avatar to have ellipse shape
.avatar-xs {
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
}

.avatar-sm {
  min-width: 3rem;
}

.avatar-md {
  min-width: 4.5rem;
}

.avatar-lg {
  min-width: 6rem;
}

.avatar-xl {
  min-width: 7.5rem;
}


// custom/plugins/_dropzone.scss
.dropzone.drag-active {
  border-color: $primary;
}

// PIPE additions

.icon-sm {
  font-size: 2rem !important;
}

.file-icon {
  font-size: 2rem !important;
}
